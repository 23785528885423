.tm_card {
    max-width: 82rem;
    display    : grid;
    align-items: center;
    gap        : 3rem;
}

.tm_card .img_box {
    width        : 10rem;
    height       : 10rem;
    border-radius: 50%;
    margin: 0 auto;
    overflow     : hidden;
}

.tm_card .img_box img {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
}

.tm_card h5 {
    font-weight: 600;
    font-size  : 1.6rem;
    color      : var(--dipBlue);
    text-align: center;
    margin-top : 1.5rem;
}

.tm_card .content {
    position     : relative;
    border       : 1px solid var(--gray-8);
    border-radius: 5px;
    padding      : 5rem 3rem;
}

.tm_card .content::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 2.6rem;
    height: 2.6rem;
    background-color:var(--white);
    border-style: solid;
    border-color: var(--gray-8);
    
}

.tm_card .content p {
    font-weight  : 400;
    font-style   : italic;
    line-height  : 3.4rem;
    color        : var(--gray-8);
    margin-bottom: 1.5rem;
}

.tm_card .content a {
    font-style : italic;
    line-height: 3.3rem;
    color      : var(--gray_1);
    text-decoration-line: underline;
}


@media (max-width: 575.98px) {

    .tm_card .content {
        padding: 3.5rem 2rem;
    }

    .tm_card .content::before {
        top: -1rem;
        left: 50% !important;
        transform: translate(-50%, 0) rotate(45deg);
        width: 1.6rem;
        height: 1.6rem;
        border-width: 1px 0 0 1px !important;
    }

    .tm_card .content p {
        line-height: 2.5rem;
        margin-bottom: 1.2rem;
    }
}