.about_us_page p,
.about_us_page b,
.about_us_page li {
    font-size  : 1.6rem;
    line-height: 2.5rem;
}

.about_us_page p,
.about_us_page li {
    font-weight: 400;
}

.about_us_page p span,
.about_us_page b {
    font-weight: 600;
}

.about_us_page b {
    display      : inline-block;
    margin-bottom: 1.5rem;
}

.about_us_page ul {
    list-style   : auto;
    padding-left : 1.5rem;
    margin-bottom: 1.5rem;
}

.about_us_page ul:last-of-type {
    list-style: initial;
}

@media (max-width: 575.98px) {

    .about_us_page p,
    .about_us_page b,
    .about_us_page li {
        font-size  : 1.4rem;
    }
}