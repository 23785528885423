.res_card {
    position: relative;
    border-radius: 5px;
    background: var(--white);
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.10);
    padding: 3rem 1.5rem;
    height:33.4rem;
}

.res_card h3 {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: normal;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.res_card p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 1.5rem 0 2.5rem;
}

.res_card button {
    position: absolute;
    left: 1.5rem;
    bottom: 3rem;
    border-radius: 5px;
    border: 1px solid var(--orange, #FF914D);
    color: var(--orange);
    padding: .5rem .7rem;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.res_card button span {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: normal;
    text-transform: capitalize;
    color: inherit;
}

.res_card button:hover {
    background-color: var(--orange);
    color: var(--white);
}

/* .file_link{
    padding-bottom: 0;
 } */

@media (max-width: 575.98px) {
    .res_card {
        padding: 2rem 1rem;
    }

    .res_card h3 {
        font-size: 2rem;
    }

    .res_card p {
        font-size: 1.2rem;
        line-height: 2rem;
        margin: 1rem 0 2rem;
    }

    .res_card button {
        padding: .4rem .6rem;
    }

    .res_card button span {
        font-size: 1.2rem;
    }
}