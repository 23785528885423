.social_wrapper>a {
    display      : flex;
    justify-self : center;
    align-items  : center;
    border       : 1px solid var(--gray-8);
    border-radius: .5rem;
    color        : var(--gray-8);
    padding      : .8rem;
    transition   : background-color .3s ease-in-out, color .3s ease-in-out, border .3s ease-in-out;
}

.social_wrapper>a:hover {
    border-color    : var(--orange);
    color           : var(--white);
    background-color: var(--orange);
}

.social_wrapper>a svg {
    width : 2.4rem;
    height: 2.4rem;
}

.social_wrapper>a:not(:last-child) {
    margin-bottom: 2rem;
}

@media (max-width: 575.98px) {
    .social_wrapper {
        display    : flex;
        align-items: center;
    }

    .social_wrapper>a:not(:last-child) {
        margin-bottom: 0;
        margin-right : 2rem;
    }
}