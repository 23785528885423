.video_banner {
    padding: 2rem 0;
}

.video_banner .bg_video {
    position     : relative;
    /* height       : 82rem; */
    height       : calc(100vh - 12rem);
    border-radius: 5px;
    overflow: hidden;
}

.video_banner .bg_video::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(239.41% 166.79% at -53% 100.00%, rgba(1, 32, 99, 0.90) 5%, rgba(0, 0, 0, 0) 77%);
}

.video_banner .bg_video video {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
}

.video_banner .content {
    position  : absolute;
    top       : 50%;
    transform : translateY(-50%);
    left      : 15.5rem;
    right     : 15.5rem;
    text-align: center;
}

.video_banner .content h2,
.video_banner .content p {
    color: var(--white);
}

.video_banner .content h2 {
    max-width  : 95.6rem;
    font-weight: 600;
    font-size  : 4.8rem;
    margin     : 0 auto 1.5rem;
}

.video_banner .content p {
    font-weight: 400;
    font-size  : 2rem;
}

.video_banner .content p:not(:last-of-type) {
    margin-bottom: 1rem;
}

.video_banner .content .btn {
    display     : inline-block;
    border-color: var(--white);
    padding     : 2rem 3rem;
    margin-top  : 5rem;
}

.video_banner .content .btn:hover {
    border-color: var(--orange);
}

.video_banner .content .btn>span {
    color: var(--white);
}

@media (max-width: 575.98px) {
    .video_banner .bg_video {
        height: 59.4rem;
    }
    .video_banner .content {
        left: 1.5rem;
        right: 1.5rem;
    }

    .video_banner .content h2 {
        font-size: 2rem;
        margin: 0 auto 1rem;
    }
    .video_banner .content p {
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .video_banner .content .btn {
        padding: 1.2rem 1.8rem;
        margin-top: 3rem;
    }
}
