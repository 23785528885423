.featured_process {
    background-color: var(--bgColor);
}

.featured_process .first_grid,
.featured_process .second_grid {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
}

.featured_process .second_grid {
    margin-top: -85px;
}

.featured_process .gird_item {
    position: relative;
}

.featured_process .gird_item .content {
    position  : absolute;
    top       : 9.2rem;
    right     : 5.2rem;
    bottom    : 9.2rem;
    left      : 5.2rem;
    text-align: center;
}

.featured_process .gird_item .content h1 {
    font-weight   : 800;
    font-size     : 7rem;
    text-transform: capitalize;
    color         : var(--orange);
    margin-bottom : 2rem;
}

.featured_process .gird_item .content h6 {
    font-weight   : 800;
    font-size     : 2rem;
    text-transform: capitalize;
    color         : var(--dipBlue);
}



@media (max-width: 575.98px) {

    .featured_process .first_grid,
    .featured_process .second_grid {
        display              : grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content      : center;
    }

    .featured_process .gird_item svg {
        width : 100%;
        height: auto;
    }

    .featured_process .gird_item .content {
        top   : 4rem;
        right : 2rem;
        bottom: 3rem;
        left  : 2rem;
    }

    .featured_process .second_grid {
        margin-top: 0;
    }

    .featured_process .second_grid .gird_item:nth-child(3) {
        transform: translate(50%, -15%);
    }

    .featured_process .gird_item .content h1 {
        font-size    : 5rem;
        line-height  : 5rem;
        margin-bottom: .5rem;
    }

    .featured_process .gird_item .content h6 {
        font-size: 1.2rem;
    }

}