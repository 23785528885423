.modal {
    z-index: 1060;
}

.modal,
.modal_backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.modal_backdrop {
    background-color: rgba(0, 0, 0, .7);
}

.modal_dialog {
    position      : relative;
    max-width     : 54.5rem;
    margin        : 1.75rem auto;
    pointer-events: none;
}

.modal.large .modal_dialog {
    max-width: 105rem;
}

.modal_dialog_scrollable {
    height: calc(100% - 3.5rem);
}

.modal_dialog_centered {
    display    : flex;
    align-items: center;
    min-height : calc(100% - 3.5rem);
}

.modal_content {
    position        : relative;
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    background-color: var(--white);
    border-radius   : .5rem;
    pointer-events  : auto;
    padding         : 4rem;
    z-index         : 2;
}

.modal_dialog_scrollable .modal_content {
    max-height: 100%;
    overflow  : hidden;
}

.modal_dialog_scrollable .modal_body::-webkit-scrollbar {
    display: none;
}

.modal_content .title_1 {
    font-size    : 2.8rem;
    text-align   : center;
    margin-bottom: 3rem;
}

.modal_dialog_scrollable .modal_body {
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.modal_body .form_wrapper .grid_2 {
    column-gap: 2.6rem;
    row-gap   : 2rem;
}

.modal_body .form_wrapper form>*:not(:last-child) {
    margin-bottom: 2rem;
}

.modal_close {
    position : absolute;
    top      : 1rem;
    right    : 1.5rem;
    font-size: 2.4rem;
    color    : var(--gray-6);
}

@media screen and (max-width: 767.98px) {
    .modal_dialog {
        margin: 1.75rem 1rem;
    }

    .modal_content {
        padding: 3rem 1.5rem;
    }

    .modal_content .title_1 {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }
    .modal_body .form_wrapper .grid_2 {
        column-gap: 1rem;
        row-gap: 1rem;
    }
    .modal_body .form_wrapper form>*:not(:last-child) {
        margin-bottom: 1rem;
    }

    .modal_close {
        top: .5rem;
        right: 1rem;
        font-size: 2rem;
    }
}