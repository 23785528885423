.client_journey.large .cj_grids {
    position: relative;
}

.client_journey.large .layout {
    position: absolute;
    top     : 12.5rem;
    left    : -.8rem;
    width   : 100%;
}

.client_journey.large .first_grid {
    display        : flex;
    justify-content: space-evenly;
}

.client_journey.large .second_grid {
    display        : flex;
    justify-content: space-between;
    margin-top     : -13.5rem;
}

.client_journey.large .content {
    height        : 100%;
    display       : flex;
    flex-direction: column;
    text-align    : center;
    max-width     : 22.6rem;
    margin        : 0 auto;
}

.client_journey.large .first_grid .content {
    justify-content: flex-end;
}

.client_journey.large .content .line {
    width           : 3px;
    height          : 1.2rem;
    background-color: var(--orange);
}

.client_journey.large .first_grid .content .line {
    margin: 2.8rem auto 8.5rem;
}

.client_journey.large .content .icon_box {
    z-index: 1;
}

.client_journey.large .first_grid .grid_item:nth-child(1) .icon_box img {
    margin-bottom: -.5rem;
    margin-left  : 1rem;
}

.client_journey.large .first_grid .grid_item:nth-child(3) .icon_box img {
    margin-left  : -1.4rem;
    margin-bottom: -.5rem;
}

.client_journey.large .second_grid .grid_item:nth-child(1) .icon_box img {
    margin-left: -1.8rem;
}

.client_journey.large .second_grid .grid_item:nth-child(4) .icon_box img {
    margin-top: 1.2rem;
}

.client_journey.large .second_grid .content .line {
    margin: 8.5rem auto 2.8rem;
}

@media (max-width: 1399.98px) {

    .client_journey.large .first_grid .grid_item:nth-child(1) .icon_box img {
        margin-bottom: -.2rem;
        margin-left  : 2rem;
    }

    .client_journey.large .second_grid .grid_item:nth-child(1) .icon_box img {
        margin-left: -2.8rem;
    }

    .client_journey.large .second_grid .grid_item:nth-child(3) .icon_box img {
        margin-right: -1.5rem;
    }

    .client_journey.large .second_grid .grid_item:nth-child(4) .icon_box img {
        margin-right: -2.5rem;
    }

    .client_journey.large .layout {
        position: absolute;
        top     : 13.5rem;
        left    : -.4rem;
    }
}