.navbar {
    position: sticky;
    top: 0;
    background: var(--white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding   : .8rem 0;
    z-index: 3;
}

@media (max-width: 575.98px) {
    .navbar {
        padding   : 1.5rem 0;
    }
}