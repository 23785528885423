.mtt_card {
    display    : grid;
    align-items: center;
    gap        : 8rem;
}

.mtt_card img {
    width        : 100%;
    border-radius: .5rem;
}

.mtt_card h4 {
    font-weight   : 600;
    font-size     : 3rem;
    color         : var(--dipBlue);
    text-transform: capitalize;
}

.mtt_card h6 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size  : 1.6rem;
    color      : #A5A5A5;
    margin     : .5rem 0 3rem;
}

.mtt_card p {
    font-weight: 400;
    line-height: 2.5rem;
    color      : var(--gray_8);
}

.mtt_card p:not(:last-child) {
    margin-bottom: 1.5rem;
}

.mtt_card p:last-child {
    font-style: italic;
    color     : var(--gray_1);
}

@media (max-width: 575.98px) {
    .mtt_card {
        gap: 4rem;
    }

    .mtt_card h4 {
        font-size: 2.5rem;
        text-align: center;
    }

    .mtt_card h6 {
        font-size: 1.4rem;
        text-align: center;
    }
}