:root {
  --containerPadding: 2.4rem;
  --containerWidth  : 144.6rem;
  --dipBlue         : #022949;
  --orange          : #FF914D;
  --bgColor         : #F8F9FA;
  --gray_1          : #6c6c6c;
}


body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size  : 1.6rem;
  line-height: normal;
}

p {
  font-family: 'Inter', sans-serif;
}


/* >>== containers start ==<< */
.container,
.container_fluid {
  width  : 100%;
  padding: 0 var(--containerPadding);
}

.container {
  max-width: calc(var(--containerWidth) + (var(--containerPadding) * 2));
  margin   : 0 auto;
}

/* >>== containers end ==<< */


/* >>== paddings start ==<< */
.pt_9,
.py_9 {
  padding-top: 9rem;
}

.pb_9,
.py_9 {
  padding-bottom: 9rem;
}

.pt_4,
.py_4 {
  padding-top: 4rem;
}

.pb_4,
.py_4 {
  padding-bottom: 4rem;
}

/* >>== paddings end ==<< */


/* >>== titles start ==<< */
.title_1 {
  font-weight: 600;
  font-size  : 4rem;
  line-height: normal;
  color      : var(--dipBlue);
}

.title_1 span {
  color: var(--orange);
}

.title_1.center {
  text-align: center;
}

/* >>== titles end ==<< */

/* >>== buttons start ==<< */
.btn {
  display      : inline-block;
  border       : 2px solid var(--dipBlue);
  border-radius: .5rem;
  transition   : border .3s ease-in-out, background-color .3s ease-in-out
}

.btn>span {
  font-size     : 2rem;
  line-height   : normal;
  line-height   : 2.4rem;
  color         : var(--dipBlue);
  text-transform: capitalize;
  transition    : color .3s ease-in-out;
}

.btn:not(.fill):hover>span {
  color: var(--white);
}

.btn.fill {
  background-color: var(--dipBlue);
  transition      : border .3s ease-in-out, background-color .3s ease-in-out;
}

.btn:hover,
.btn.fill:hover {
  border          : 2px solid var(--orange);
  background-color: var(--orange);
}

.btn.fill>span {
  color: var(--white);
}

/* >>== buttons end ==<< */

/* >>== form start ==<< */
.form_wrapper .grid_2 {
  display              : grid;
  grid-template-columns: repeat(2, 1fr);
  gap                  : 4rem;
}

.form_wrapper form>*:not(:last-child) {
  margin-bottom: 4rem;
}

/* .form_wrapper .grid_2>*:last-child {
  grid-column: 1 / -1;
} */

.form_wrapper .input_label {
  display      : block;
  font-weight  : 500;
  font-size    : 1.6rem;
  line-height  : normal;
  color        : var(--dipBlue);
  margin-bottom: 1rem;
}

.form_wrapper .input_field {
  width        : 100%;
  font-weight  : 400;
  font-size    : 1.4rem;
  line-height  : normal;
  color        : #5E718D;
  border-radius: 3px;
  border       : 1px solid #E1E1E1;
  background   : #EDF7FD;
  padding      : 1.8rem 1.5rem;
}

.form_wrapper .input_field::placeholder {
  color: #5E718D;
}

.form_wrapper .input_field.date {
  position     : relative;
  padding-right: 4.4rem;
}

.form_wrapper .input_field.date::-webkit-calendar-picker-indicator {
  position        : absolute;
  right           : 1.5rem;
  background-image: url(./Assets/Icons/calender.svg);
  width           : 2.4rem;
  height          : 2.4rem;
}

.form_wrapper .input_field.select {
  appearance         : none;
  background-image   : url(./Assets/Icons/chevron-down.svg);
  background-repeat  : no-repeat;
  background-position: right 1.5rem center;
  background-size    : 2.4rem 2.4rem;
  padding-right      : 4.4rem;
}

.form_wrapper .btn {
  width        : 100%;
  padding      : 2.2rem 2.4rem;
  /* margin-top: 3.6rem; */
}

/* >>== form end ==<< */


/* >>== policy terms start ==<< */
.pp_content_box p {
  font-weight: 400;
  font-size  : 1.6rem;
  line-height: 2.5rem;
  color      : var(--gray-8);

}

.pp_content_box p:not(:last-child) {
  margin-bottom: 3rem;
}

/* >>== policy terms end- ==<< */


/* responsive start */
@media (max-width: 575.98px) {
  :root {
    --containerPadding: 1.6rem;
  }

  body {
    font-size: 1.4rem;
  }

  /* >>== paddings start ==<< */
  .pt_9,
  .py_9 {
    padding-top: 4rem;
  }

  .pb_9,
  .py_9 {
    padding-bottom: 4rem;
  }

  /* >>== paddings end ==<< */

  .title_1 {
    font-size : 3rem;
    text-align: center;
  }


  .btn>span {
    font-size: 1.8rem;
  }

  .form_wrapper .input_label {
    font-size: 1.4rem;
  }

  .form_wrapper .input_field {
    font-size: 1.3rem;
    padding  : 1.5rem 1.2rem;
  }

  .form_wrapper .input_field.select {
    background-position: right 1rem center;
    background-size    : 2rem 2rem;
    padding-right      : 3rem;
  }

  .form_wrapper .input_field.date::-webkit-calendar-picker-indicator {
    right : 1rem;
    width : 2rem;
    height: 2rem;
  }

  .form_wrapper .btn {
    width     : 100%;
    padding   : 1.6rem;
    margin-top: .5rem;
  }

  /* >>== policy terms start ==<< */
  .pp_content_box p {
    font-size  : 1.4rem; 
    line-height: 2.2rem;

  }

  .pp_content_box p:not(:last-child) {
    margin-bottom:  2rem;
  }

  /* >>== policy terms end- ==<< */

}