.contact_us_page .grid_container {
    display              : grid;
    grid-template-columns: 5fr 7fr;
}

.contact_us_page .grid_container>*:nth-child(1) {
    padding-right: 23.8rem;
    position     : relative;
}

.contact_us_page .grid_container>*:nth-child(1)::after {
    content         : '';
    position        : absolute;
    top             : 10rem;
    right           : 11.9rem;
    background-color: rgba(108, 108, 108, .3);
    width           : 1px;
    height          : 80%;
}

.contact_us_page .title_1 {
    margin-bottom: 2.5rem;
}

.contact_us_page p {
    font-weight  : 400;
    line-height  : 2.5rem;
    color        : var(--gray-8);
    margin-bottom: 1rem;
}

.contact_us_page .contacts_wrapper>* {
    padding: 3rem 0;
}

.contact_us_page .contacts_wrapper>*:not(:last-child) {
    border-bottom: 1px solid rgba(108, 108, 108, .3);
}

.contact_us_page .contacts_wrapper h6 {
    font-family  : 'Inter', sans-serif;
    font-weight  : 400;
    font-size    : 1.6rem;
    line-height  : 3rem;
    color        : var(--gray-8);
    margin-bottom: 1rem;
}

.contact_us_page .contacts_wrapper a {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size  : 2.2rem;
    line-height: 3rem;
    color      : var(--gray-8);
    display    : flex;
    align-items: center;
    gap        : 1.5rem;
}

.contact_us_page .contacts_wrapper a:not(:last-of-type) {
    margin-bottom: 1rem;
}

.contact_us_page .contacts_wrapper a svg {
    width : 2.4rem;
    height: 2.4rem;
}

.contact_us_page .contacts_wrapper .social_wrapper {
    display    : flex;
    align-items: center;
    gap        : 2rem;
}

.contact_us_page .contacts_wrapper .social_wrapper a:not(:last-of-type) {
    margin-bottom: 0;
}

@media (max-width: 575.98px) {
    .contact_us_page .grid_container {
        grid-template-columns: 1fr;
    }

    .contact_us_page .title_1 {
        text-align   : left;
        margin-bottom: 1.5rem;
    }

    .contact_us_page p {
        margin-bottom: 0;
    }

    .contact_us_page .grid_container>*:nth-child(1) {
        padding-right : 0;
        padding-bottom: 8rem;
    }

    .contact_us_page .grid_container>*:nth-child(1)::after {
        height: 0;
    }

    .contact_us_page .contacts_wrapper>* {
        padding: 2rem 0;
    }

    .contact_us_page .contacts_wrapper h6 {
        font-size  : 1.4rem;
        line-height: 2.6rem;
    }

    .contact_us_page .contacts_wrapper a {
        font-size  : 1.8rem;
        line-height: 2.6rem;
        gap        : 1rem;
    }

    .contact_us_page .contacts_wrapper a svg {
        width : 2rem;
        height: 2rem;
    }

    .contact_us_page .contacts_wrapper .social_wrapper {
        gap: 0;
    }

    .contact_us_page .form_wrapper .grid_2 {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .form_wrapper form>*:not(:last-child) {
        margin-bottom: 2rem;
    }
}