.process_card {
    display    : grid;
    align-items: center;
    gap        : 5rem;
}

.process_card .img_box {
    position: relative;
}

.process_card .img_box img {
    width: 100%;
}

.process_card .img_box .content {
    position       : absolute;
    top            : 0;
    left           : 0;
    width          : 100%;
    height         : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.process_card .img_box h1 {
    font-weight   : 800;
    font-size     : 7rem;
    color         : var(--orange);
    text-transform: capitalize;
}



.process_card h5 {
    font-weight   : 800;
    font-size     : 2rem;
    color         : var(--dipBlue);
    text-transform: capitalize;
    margin-bottom : 2rem;
}

.process_card {
    font-weight: 400;
    line-height: 2.5rem;
    color      : var(--gray-8);
}

@media (max-width: 575.98px) {
    .process_card {
        gap        : 0;
    }

    .process_card .img_box {
        text-align: center;
    }
    .process_card .img_box img {
        max-width: 29rem;
    }

    .process_card h5 {
        font-size: 1.7rem;
        margin-bottom: 1.5rem;
    }
}