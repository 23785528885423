.process_page .process_card:nth-child(odd) {
    grid-template-columns: 28rem auto;
}

.process_page .process_card:nth-child(even) {
    grid-template-columns: auto 28rem;
}

.process_page .process_card:nth-child(even)>*:nth-child(2) {
    order: -1;
}

@media (max-width: 575.98px) {
    .process_page .process_card:not(:last-child) {
        padding-bottom: 3rem;
    }

    .process_page .process_card:nth-child(odd) {
        grid-template-columns: 1fr;
    }

    .process_page .process_card:nth-child(even) {
        grid-template-columns: 1fr;
    }

    .process_page .process_card:nth-child(even)>*:nth-child(2) {
        order: initial;
    }
}