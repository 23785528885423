.featured_testimonials.py_9 {
    padding-bottom: 19rem;
}

.featured_testimonials .flex_container {
    display  : flex;
    flex-wrap: wrap;
    margin   : 0 -8rem;
}

.featured_testimonials .flex_container>* {
    padding: 0 8rem;
}

.featured_testimonials .flex_container>*:nth-child(1) {
    flex : 0 0 33.333333%;
    width: 33.333333%;
}

.featured_testimonials .flex_container>*:nth-child(2) {
    flex : 0 0 66.333333%;
    width: 66.333333%;
}

.featured_testimonials .content p {
    font-weight: 400;
    line-height: 2.5rem;
    color      : var(--gray_1);
    margin     : 2rem 0 4rem;
}

.featured_testimonials .btn {
    padding: 1.4rem 3.8rem;
}

.featured_testimonials .testimonials {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap              : 3rem;
    column-gap           : 2rem;
}

.featured_testimonials .testimonials .item:nth-child(odd) {
    margin-top   : 10rem;
    margin-bottom: -10rem;
}

.featured_testimonials .testimonials .item {
    border-radius   : .5rem;
    background-color: #F4F5F7;
    padding         : 3.5rem 3rem;
}

.featured_testimonials .testimonials h6 {
    font-weight  : 500;
    font-size    : 1.6rem;
    line-height  : 3.3rem;
    color        : var(--gray-8);
    font-style   : italic;
    margin-bottom: .5rem;
}

.featured_testimonials .testimonials a {
    font-weight    : 500;
    font-size      : 1.6rem;
    line-height    : 3.3rem;
    color          : var(--gray_1);
    font-style     : italic;
    text-decoration: underline;
    text-transform : capitalize;
}

.featured_testimonials .testimonials .thumb_name {
    display    : flex;
    align-items: center;
    gap        : 1.5rem;
    margin-top : 5rem;
}

.featured_testimonials .testimonials .thumb_name>*:nth-child(1) {
    flex : 0 0 6.2rem;
    width: 6.2rem;
}

.featured_testimonials .testimonials .thumb_name img {
    width          : inherit;
    height         : 6.2rem;
    border-radius  : 50%;
    object-fit     : cover;
    object-position: center;
}

.featured_testimonials .testimonials h5 {
    font-weight: 600;
    font-size  : 1.6rem;
    color      : var(--dipBlue);
}

.featured_testimonials .sm_only {
    display: none;
}

@media (max-width: 575.98px) {

    .featured_testimonials.py_9 {
        padding-bottom: 4rem;
    }

    .featured_testimonials .content p {
        text-align: center;
        margin    : 1.5rem 0 4rem;
    }

    .featured_testimonials .content .btn {
        display: none
    }

    .featured_testimonials .flex_container {
        margin: 0;
    }

    .featured_testimonials .flex_container>* {
        padding: 0;
    }

    .featured_testimonials .flex_container>*:nth-child(1),
    .featured_testimonials .flex_container>*:nth-child(2) {
        flex : 0 0 100%;
        width: 100%;
    }

    .featured_testimonials .testimonials {
        grid-template-columns: 1fr;
    }

    .featured_testimonials .testimonials .item:nth-child(odd) {
        margin-top   : 0;
        margin-bottom: 0;
    }

    .featured_testimonials .testimonials .item {
        padding: 2.8rem 2.4rem;
    }

    .featured_testimonials .testimonials h6 {
        font-size  : 1.4rem;
        line-height: 2.6rem;
    }

    .featured_testimonials .testimonials .thumb_name {
        gap       : 1.2rem;
        margin-top: 3.5rem;
    }

    .featured_testimonials .testimonials .thumb_name>*:nth-child(1) {
        flex : 0 0 5rem;
        width: 5rem;
    }

    .featured_testimonials .testimonials .thumb_name img {
        height: 5rem;
    }

    .featured_testimonials .testimonials h5 {
        font-size: 1.4rem;
    }
    .featured_testimonials .testimonials a {
        font-size: 1.4rem;
        line-height: 2.6rem;
    }

    .featured_testimonials .sm_only {
        display   : block;
        text-align: center;
        margin-top: 4rem;
    }
}