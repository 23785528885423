.mtt_page .mtt_card:not(:last-child) {
    padding-bottom: 9rem;
}

.mtt_page .mtt_card:nth-child(odd) {
    grid-template-columns: 52.9rem auto;
}

.mtt_page .mtt_card:nth-child(even) {
    grid-template-columns: auto 52.9rem;
}

.mtt_page .mtt_card:nth-child(even)>*:nth-child(2) {
    order: -1;
}

@media (max-width: 575.98px) {
    .mtt_page .mtt_card:not(:last-child) {
        padding-bottom: 4.5rem;
    }
    
    .mtt_page .mtt_card:nth-child(odd) {
        grid-template-columns: 1fr;
    }
    
    .mtt_page .mtt_card:nth-child(even) {
        grid-template-columns: 1fr;
    }
    
    .mtt_page .mtt_card:nth-child(even)>*:nth-child(2) {
        order: initial;
    }
}