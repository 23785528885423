.who_we_are {
    background-color: var(--bgColor);
}

.who_we_are .grid_container {
    display              : grid;
    grid-template-columns: 69.2rem auto;
    align-items          : flex-end;
    gap                  : 11rem;
}

.who_we_are .grid_container>*:nth-child(1) {
    position: relative;
}

.who_we_are .grid_container>*:nth-child(1) img {
    position: absolute;
    left    : 0;
    bottom  : 0;
    width   : 100%;
}

@media (max-width: 575.98px) {
    .who_we_are .grid_container {
        grid-template-columns: 1fr;
        gap                  : 4rem;
    }

    .who_we_are .grid_container>*:nth-child(1) img {
        position: initial;
    }

    .who_we_are .title_1.pb_4 {
        padding-bottom: 3rem;
    }
}