.nav_large {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_large .logo_wrapper .logo_lg {
    width: 7.6rem;
}

.nav_large .logo_wrapper .logo_sm,
.nav_large .btn_nst {
    display: none;
}

.nav_large nav>a {
    color: var(--dipBlue);
    text-transform: capitalize;
    padding: 1rem;
    transition: color .3s ease-in-out;
}

.nav_large nav>a:not(:last-child) {
    margin-right: 2.5rem;
}

.nav_large nav>a:hover,
.nav_large nav>a.active {
    color: var(--orange);
}

.nav_large nav .btn {
    padding: 1.4rem 2.4rem;
}


@media (max-width: 1399.98px) {
    .nav_large .logo_wrapper .logo_lg {
        width: 6.5rem;
    }

    .nav_large nav>a {
        padding: .8rem;
    }

    .nav_large nav>a:not(:last-child) {
        margin-right: .8rem;
    }

    .nav_large nav .btn {
        padding: 1.2rem 2rem;
    }
}

@media (max-width: 1199.98px) {
    .nav_large .logo_wrapper .logo_lg {
        width: 5.5rem;
    }

    .nav_large nav>a {
        font-size: 1.4rem;
        padding: .5rem;
    }

    .nav_large nav>a:not(:last-child) {
        margin-right: .5rem;
    }

    .nav_large nav .btn {
        padding: .8rem 1.2rem;
    }
    .nav_large nav .btn span {
        font-size: 1.6rem;
        line-height: 2.2rem;
     }
}

@media (max-width: 991.98px) {

    .nav_large .logo_wrapper .logo_lg,
    .nav_large nav {
        display: none;
    }

    .nav_large .logo_wrapper .logo_sm,
    .nav_large .btn_nst {
        display: block;
    }

}