.featured_purchases .grid_container {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    gap                  : 2rem;
}

.featured_purchases .grid_item {
    position     : relative;
    border-radius: 5px;
    background   : linear-gradient(360deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
    overflow     : hidden;
}

.featured_purchases .grid_item::before {
    content   : '';
    position  : absolute;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.featured_purchases .grid_item img {
    width: 100%;
}

.featured_purchases .content {
    position: absolute;
    left    : 3.5rem;
    bottom  : 2rem;
}

.featured_purchases .content h4,
.featured_purchases .content p {
    color: var(--white);
}

.featured_purchases .content h4 {
    font-weight  : 600;
    font-size    : 2.4rem;
    margin-bottom: .5rem;
}

.featured_purchases .content p {
    font-weight: 400;
}


@media (max-width: 575.98px) {
    .featured_purchases .grid_container {
        grid-template-columns: 1fr;
    }

    .featured_purchases .content {
        left: 2rem;
        bottom: 1.5rem;
    }

    .featured_purchases .content h4 {
        font-size: 1.6rem;
    }

    .featured_purchases .content p {
        font-size: 1.2rem;
    }
}