.footer {
    background-color: #F4F5F7;
}

.footer_main,
.footer_bottom {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
}

.footer_main {
    padding: 8rem 0 5rem;
}

.footer_main .logo_text_wrapper img {
    max-width: 10.5rem;
}

.footer_main .logo_text_wrapper p {
    max-width  : 39rem;
    font-weight: 400;
    line-height: 2.4rem;
    color      : var(--gray-8);
    margin-top : 3rem;
}

.footer_main .links_wrapper a {
    display     : block;
    position    : relative;
    font-family : 'Inter', sans-serif;
    font-weight : 400;
    font-size   : 1.6rem;
    color       : var(--gray-8);
    padding-left: 3rem;
}

.footer_main .links_wrapper a:not(:last-child) {
    margin-bottom: 1.5rem;
}

.footer_main .links_wrapper a::before {
    content         : '';
    position        : absolute;
    top             : 50%;
    transform       : translateY(-50%);
    left            : 0;
    width           : 2rem;
    height          : 2px;
    background-color: var(--gray-8);
}

.footer_bottom {
    align-items: center;
    border-top : 1px solid #e1e1e1;
    padding    : 2.5rem 0;
}

.footer_bottom p {
    font-weight: 300;
    font-size  : 1.3rem;
    line-height: normal;
}

@media (max-width: 575.98px) {
    .footer_main {
        padding: 4rem 0;
    }

    .footer_main,
    .footer_bottom {
        display: block;
    }

    .footer_main>*:not(:last-child) {
        margin-bottom: 4rem;
    }

    .footer_main .logo_text_wrapper p {
        max-width : 100%;
        font-size : 1.4rem;
        margin-top: 1.5rem;
    }

    .footer_main .links_wrapper a {
        font-weight : 500;
        padding-left: 2.5rem;
    }

    .footer_main .links_wrapper a::before {
        width: 1.5rem;
    }

    .footer_bottom {
        text-align: center;
    }

    .footer_bottom>*:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}