.rp_card {
    border-radius: 5px;
    box-shadow   : 0px 0px 20px 0px rgba(51, 51, 51, 0.10);
    overflow     : hidden;
}

.rp_card p {
    font-weight: 400;
}

.rp_card p span {
    font-weight: 600;
}

.rp_card img {
    width: 100%;
}

.rp_card .d_flex {
    display    : flex;
    align-items: center;
    gap        : 2rem;
}

.rp_card .d_flex>.d_flex {
    gap: .5rem;
}

.rp_card .content {
    padding      : 1.5rem 1rem 3rem;
    border-bottom: 1px solid var(--gray-1);
}

.rp_card .content>.d_flex p {
    color: var(--gray_1);
}

.rp_card .content svg {
    width : 2.4rem;
    height: 2.4rem;
}

.rp_card .content h2 {
    font-weight   : 600;
    font-size     : 2.6rem;
    color         : var(--dipBlue);
    text-transform: capitalize;
    margin        : 1.5rem 0 2rem;
}

.rp_card .content .desc {
    line-height: 2.5rem;
    color      : var(--gray-8);
}

.rp_card .meta_box {
    padding: 2rem 1rem;
}

.rp_card .meta_box h4 {
    font-weight: 600;
    font-size  : 2rem;
    color      : var(--orange);
}

.rp_card .meta_box>.d_flex {
    justify-content: space-between;
}

.rp_card .meta_box>*>*:nth-child(1)>p {
    color     : var(--orange);
    margin-top: .5rem;
}

.rp_card .meta_box>*>*:nth-child(2)>*>p {
    font-weight: 400;
    line-height: 2.4rem;
    color      : var(--gray_1);
}


@media (max-width: 575.98px) {
    .rp_card .content {
        padding: 1rem .8rem 1.5rem;
    }

    .rp_card svg {
        width : 1.6rem;
        height: 1.6rem;
    }

    .rp_card p {
        font-size  : 1.2rem;
        line-height: 2rem;
    }

    .rp_card .content h2 {
        font-size: 1.9rem;
        margin   : 1rem 0 1.2rem;
    }

    .rp_card .meta_box {
        padding: 1.5rem .8rem;
    }

    .rp_card .meta_box h4 {
        font-size: 1.5rem;
    }

    .rp_card .meta_box>*>*:nth-child(2)>p {
        line-height: 1.8rem;
    }
}