.client_journey.small .cj_grids>.grid {
    display    : grid;
    column-gap : 2.4rem;
    align-items: center;
}

.client_journey.small .cj_grids>.grid:nth-child(odd) {
    grid-template-columns: 9rem auto;
}

.client_journey.small .cj_grids>.grid:nth-child(even) {
    grid-template-columns: auto 9rem;
}

.client_journey.small .cj_grids>.grid:nth-child(odd) .icon_box_wrapper {
    order: -1;
}

.client_journey.small .cj_grids>.grid:not(:last-child) {
    margin-bottom: 4rem;
}

.client_journey.small .icon_box {
    width           : 9rem;
    height          : 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border          : 1rem solid var(--dipBlue);
    border-radius   : 50%;
    background-color: var(--white);
}
