.nav_small {
    display: none;
}

@media (max-width: 991.98px) {
    .nav_small {
        display: block;
    }

    .nav_small .nav_smal_overlay {
        position  : fixed;
        top       : 0;
        left      : 0;
        width     : 100%;
        height    : 100%;
        background: rgba(0, 0, 0, 0.50);
        opacity   : 0;
        visibility: hidden;
        transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
    }

    .nav_small.show .nav_smal_overlay {
        opacity   : 1;
        visibility: visible;
    }

    .nav_small .nav_small_content {
        width           : 33rem;
        position        : fixed;
        top             : 0;
        right           : 0;
        transform       : translateX(33rem);
        background-color: var(--white);
        padding         : 2rem 1.6rem 4rem;
        transition      : transform .3s ease-in-out;
    }

    .nav_small.show .nav_small_content {
        transform: translateX(0);
    }

    .nav_small .btn_close {
        position: absolute;
        top     : 1.4rem;
        right   : 1.6rem;
    }

    .nav_small .btn_close>svg {
        width : 2rem;
        height: 2rem;
    }

    .nav_small_content .logo_wrapper img {
        width: 10.5rem;
    }

    .nav_small_content nav {
        border-style: solid;
        border-color: rgba(211, 211, 211, .3);
        border-width: 1px 0 1px 0;
        padding     : 3rem 0;
        margin      : 3.5rem 0;
    }

    .nav_small_content nav>a {
        display       : block;
        color         : var(--dipBlue);
        text-transform: capitalize;
        text-align    : center;
        transition    : color .3s ease-in-out;
    }

    .nav_small_content nav>a:not(:last-child) {
        margin-bottom: 3.5rem;
    }

    .nav_small_content nav>a:hover,
    .nav_small_content nav>a.active {
        color: var(--orange);
    }


    .nav_small_content .text_center {
        text-align: center;
    }

    .nav_small_content .btn {
        padding: 1.4rem 2.4rem;
    }
}