.client_journey.small {
    display: none;
}

.client_journey {
    background-color: var(--bgColor);
}

.client_journey .content h5 {
    font-weight   : 600;
    font-size     : 2rem;
    line-height   : normal;
    text-transform: uppercase;
    color: var(--dipBlue);
    margin-bottom : 1.5rem;
}

.client_journey .content p {
    font-weight: 400;
    font-size  : 1.4rem;
    line-height: 2.2rem;
    color      : var(--gray_1);
}

.client_journey .icon_box img {
    width : 5rem;
    height: 5rem;
}


@media (max-width: 575.98px) {
    .client_journey.small {
        display: block;
    }

    .client_journey.large {
        display: none;
    }

    .client_journey .content h5 {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    .client_journey .content p {
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .client_journey .icon_box img {
        width : 4rem;
        height: 4rem;
    }
}