.resources .grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap:  5rem;
}

@media (max-width: 575.98px) {
    .resources .grid_container {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap:  3rem;
    }
}