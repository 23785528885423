.testimonials_page .tm_card:not(:last-child) {
    padding-bottom: 6rem;
}

.testimonials_page .tm_card:nth-child(odd) {
    grid-template-columns: 15rem auto;
}

.testimonials_page .tm_card:nth-child(even) {
    margin-left          : auto;
    grid-template-columns: auto 15rem;
}

.testimonials_page .tm_card:nth-child(even)>*:nth-child(2) {
    order: -1;
}

.testimonials_page .tm_card:nth-child(odd) .content::before {
    left        : -1.4rem;
    border-width: 0 0 1px 1px;
}

.testimonials_page .tm_card:nth-child(even) .content::before {
    right       : -1.4rem;
    border-width: 1px 1px 0 0;
}


@media (max-width: 575.98px) {
    .testimonials_page .tm_card:nth-child(odd) {
        grid-template-columns: 1fr;
    }

    .testimonials_page .tm_card:nth-child(even) {
        grid-template-columns: 1fr;
    }

    .testimonials_page .tm_card:nth-child(even)>*:nth-child(2) {
        order: initial;
    }

    .testimonials_page .tm_card:nth-child(odd) .content::before {
        left        : auto;
        border-width: 0;
    }

    .testimonials_page .tm_card:nth-child(even) .content::before {
        right        : auto;
        border-width: 0;
    }
}