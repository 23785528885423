.single_res h3 {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: normal;
    text-transform: capitalize;
}

.single_res p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-top: 2rem;
}