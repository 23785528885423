.faq_page .heading {
    text-align: center;
}

.faq_page .heading p {
    font-weight: 400;
    font-size  : 1.6rem;
    line-height: 2.5rem;
    color      : var(--gray-8);
    margin-top : 2rem;
}

.accordion_item {
    border-top   : 0.5px solid rgba(108, 108, 108, .3);
    border-bottom: 0.5px solid rgba(108, 108, 108, .3);
    padding      : 3rem 0;
    margin-top   : -1px;
}

.accordion_item:first-child {
    border-top: 0;
}

.accordion_body {
    height    : 0;
    overflow  : hidden;
    transition: height .3s ease-in-out;
}

.accordion_item.open .accordion_body {
    height: initial;
}

.accordion_body_inner {
    padding-top: 2rem;
}

.accordion_body p {
    font-weight: 400;
    font-size  : 1.6rem;
    line-height: 2.5rem;
    color      : var(--gray-8);
}

.accordion_header h4 {
    position      : relative;
    font-size     : 2rem;
    font-weight   : 700;
    line-height   : normal; 
    text-transform: capitalize;
    color      : var(--dipBlue);
    padding-right: 2.4rem;
    cursor: pointer;
}

.accordion_item.open .accordion_header h4 {
    color      : var(--orange);
}

.accordion_header h4::after {
    position         : absolute;
    content          : '';
    right            : 0;
    top              : 50%;
    transform        : translateY(-50%);
    width            : 2.4rem;
    height           : 2.4rem;
    background-image : url('../../../Assets/Icons/faq-down.svg');
    background-repeat: no-repeat;
    background-size  : 2.4rem;
}

.accordion_item.open .accordion_header h4::after {
    background-image: url('../../../Assets/Icons/chevron-up-yellow.svg');
}


@media (orientation: portrait) {
    .accordion_item {
        padding: 2rem 0;
    }

    .accordion_header h4 {
        font-weight: 600;
        font-size: 1.7rem;
    }

    .accordion_header h4::after {
        width          : 2rem;
        height         : 2rem;
        background-size: 2rem;
    }

    .accordion_body_inner {
        padding-top: 1.5rem;
    }

    .accordion_body p {
        font-size: 1.4rem;
    }

}