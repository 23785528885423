.single_testimonial .st_name_pic {
    text-align: center;
}

.single_testimonial .img_box {
    width        : 10rem;
    height       : 10rem;
    border-radius: 50%;
    overflow     : hidden;
    margin       : 0 auto;
}

.single_testimonial .img_box img {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
}

.single_testimonial h5 {
    font-weight: 600;
    font-size  : 1.6rem;
    color      : var(--dipBlue);
    margin-top : 1.5rem;
}

.single_testimonial .desc_box svg.quote {
    width : 8rem;
    height: 8rem;
}

.single_testimonial .desc_box p {
    font-weight: 400;
    font-size  : 1.6rem;
    line-height: 3.3rem;
    color      : var(--gray-8);
}

.single_testimonial .desc_box p:not(:last-child) {
    margin-bottom: 2rem;
}

@media (max-width: 575.98px) {
    .single_testimonial .st_name_pic {
        margin-bottom: 1.5rem;
    }

    .single_testimonial .desc_box svg.quote {
        width: 5rem;
        height: 5rem;
    }

    .single_testimonial .desc_box p {
        font-size: 1.4rem;
        line-height: 2.5rem;
    }
}