.rp_page .grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5rem;
    column-gap: 2.4rem;
}

@media (max-width: 575.98px) {
    .rp_page .grid_container {
        grid-template-columns: 1fr;
        row-gap: 4rem;
    }
}